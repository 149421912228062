document.addEventListener("DOMContentLoaded", function () {
	var tabs = document.querySelectorAll('.type-select');
	tabs.forEach(function(tab) {
		tab.addEventListener('change', function(evt) {
				var tabBtn = document.querySelector('#' + evt.currentTarget.value);
				var tabTrigger = new bootstrap.Tab(tabBtn);
				tabTrigger.show();
		});
	});
});
