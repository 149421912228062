//import css
import "./scss/main.scss";

//import components js
import "./components/backtotop/backtotop.js";
import "./components/breadcrumbs/breadcrumbs.js";
import "./components/button/button.js";
import "./components/callout/callout.js";
import "./components/card/card.js";
import "./components/carousel/carousel.js";
import "./components/collapse/collapse.js";
import "./components/cols-2/cols-2.js";
import "./components/cols-card/cols-card.js";
import "./components/cols-map/cols-map.js";
import "./components/cols3List/cols3List.js";
import "./components/detail-block/detail-block.js";
import "./components/dropdown/dropdown.js";
import "./components/filters/filters.js";
import "./components/footer/footer.js";
import "./components/gallery/gallery.js";
import "./components/header-center/header-center.js";
import "./components/header-nav/header-nav.js";
import "./components/header-slim/header-slim.js";
import "./components/header/header.js";
import "./components/hero-card/hero-card.js";
import "./components/hero-img/hero-img.js";
import "./components/hero/hero.js";
import "./components/image-loader/image-loader.js";
import "./components/image/image.js";
import "./components/last-update/last-update.js";
import "./components/list-item/list-item.js";
import "./components/list/list.js";
import "./components/megamenu/megamenu.js";
import "./components/modal/modal.js";
import "./components/organigramma/organigramma.js";
import "./components/page404/page404.js";
import "./components/pagination/pagination.js";
import "./components/referenti/referenti.js";
import "./components/regions/regions.js";
import "./components/search-popup/search-popup.js";
import "./components/search-row/search-row.js";
import "./components/section-wrapper/section-wrapper.js";
import "./components/simple-cta/simple-cta.js";
import "./components/skiplinks/skiplinks.js";
import "./components/spinner/spinner.js";
import "./components/tab-pane/tab-pane.js";
import "./components/tabs/tabs.js";
import "./components/tag/tag.js";
import "./components/text-img/text-img.js";
import "./components/text-zone/text-zone.js";
import "./components/textMore/textMore.js";
import "./components/video/video.js";

import "./js/browser-detect";
import "./js/lazyload";
