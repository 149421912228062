/* Search */
var search_wrap = $('.search-link');
var search_container = $('.search-popup');


$(document).on('click', '.search-link', function(e) {
	e.preventDefault();
	search_container = $('.search-popup');
	$(search_container).show();
	$(search_container).addClass('is-open');
});

$(document).on('click', function(e) {
	var container = $(".it-search-wrapper");
	if (!container.is(e.target) && container.has(e.target).length === 0)
	{
		search_container.hide();
	}
})

$(search_container).click(function(e){
	e.stopPropagation();
})

